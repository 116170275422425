.footer {
    background-color: #333;
    color: #fff;
    padding: 2rem 2rem;
    text-align: center;
}

.footer-content p {
    font-size: 1rem;
    margin: 0.5rem 0;
}

.footer-content p:first-child {
    font-weight: bold;
}

.social-links {
    margin-top: 1rem;
}

.social-icon {
    margin: 0 15px;
    display: inline-block;
}

.social-icon img {
    width: 35px;  /* Adjust size as needed */
    height: 35px;
    transition: transform 0.3s ease, filter 0.3s ease;
}

.social-icon:hover img {
    transform: scale(1.1);
    filter: brightness(0.8); /* Slightly darkens the image on hover */
}
