.products {
    padding: 4rem 2rem;
    text-align: center;
}

.products h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
}

.products-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.product-card {
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 300px;
    text-align: center;
    padding: 1rem;
    transition: transform 0.3s ease-in-out;
}

.product-card:hover {
    transform: scale(1.05);
}

.product-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 1rem;
}

.product-card h3 {
    font-size: 1.6rem;
    color: #333;
    margin: 1rem 0;
}

.product-card p {
    font-size: 1rem;
    color: #555;
}
