/* Contact Section */
.contact {
    padding: 5rem 2rem;
    background-color: #f4f4f4;
    text-align: center;
}

.contact h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #333;
}

.contact-info {
    display: flex;
    justify-content: space-around;
    margin-bottom: 3rem;
}

.contact-item {
    width: 30%;
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.contact-item h3 {
    font-size: 1.2rem;
    color: #007BFF;
    margin-bottom: 1rem;
}

.contact-item p {
    font-size: 1rem;
    color: #333;
}

.contact-item a {
    color: #007BFF;
    text-decoration: none;
}

.contact-item a:hover {
    text-decoration: underline;
}

/* Contact Form Styling */
.contact-form {
    background-color: #fff;
    padding: 3rem 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 50%;
    margin: 0 auto;
}

.contact-form h3 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #333;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
    color: #333;
    box-sizing: border-box;
}

.contact-form input:focus,
.contact-form textarea:focus {
    outline: none;
    border-color: #007BFF;
}

.contact-form button {
    width: 100%;
    padding: 1rem;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.contact-form button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .contact-info {
        flex-direction: column;
    }

    .contact-item {
        width: 80%;
        margin: 1rem auto;
    }

    .contact-form {
        width: 80%;
    }
}
