/* src/components/About.css */

.about {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4rem 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
}

.about-content {
    flex: 1;
    max-width: 50%;
    padding-right: 2rem;
}

.about h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
}

.about p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
}

.about-image {
    flex: 1;
    max-width: 50%;
    padding-left: 2rem;
}

.about-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
}

@media (max-width: 768px) {
    .about {
        flex-direction: column;
        text-align: center;
    }

    .about-content {
        max-width: 100%;
        padding-right: 0;
        margin-bottom: 2rem;
    }

    .about-image {
        max-width: 100%;
        padding-left: 0;
    }
}
