/* Header.css */

/* Wrapper for centering and rounded corners */
.header-container {
    position: fixed;
    top: 1rem; /* Distance from the top */
    left: 50%;
    transform: translateX(-50%);
    max-width: 1200px;
    width: 90%;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    backdrop-filter: blur(5px); /* Adds a modern blur effect */
    overflow: hidden;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
}

header h1 {
    font-size: 1.6rem;
    font-weight: bold;
    color: #333333;
    margin: 0;
}

nav {
    display: flex;
    gap: 1.5rem;
}

nav a {
    text-decoration: none;
    font-size: 1rem;
    color: #333333;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    padding-bottom: 0.2rem;
    border-bottom: 2px solid transparent;
}

nav a:hover {
    color: #007BFF;
    border-bottom: 2px solid #007BFF;
}

/* Responsive Menu for Smaller Screens */
@media (max-width: 768px) {
    header {
        flex-direction: column;
        padding: 1.5rem;
    }
    nav {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
} 
