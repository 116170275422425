/* src/components/Hero.css */

.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8rem 2rem 4rem; /* Add top padding for navbar space */
    min-height: 80vh;
    color: #ffffff;
    background-image: url('images/b.jpg'); /* Replace with your image path */
    background-size: cover;
    background-position: center;
    text-align: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.5); /* Dark overlay */
}

.hero-button {
    padding: 0.75rem 1.5rem; /* Button size */
    font-size: 1rem;
    background-color: #007BFF; /* Modern blue color */
    color: white;
    border: none;
    border-radius: 25px; /* Rounded corners */
    cursor: pointer;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-top: 1rem; /* Space above the button */
}

.hero-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
    transform: scale(1.05); /* Slightly enlarge on hover */
}

/* src/components/Hero.css */

.hero h1 {
    font-size: 2.5rem; /* Increase the font size of the heading */
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 1rem; /* Space below the heading */
}

.hero p {
    font-size: 1.25rem; /* Increase the font size of the paragraph */
    color: #ffffff;
    margin-bottom: 1.5rem; /* Space below the paragraph */
}
